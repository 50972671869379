const CHAIN_API_URL = process.env.VUE_APP_BACKEND;
const CHAIN_LCD_URL = process.env.VUE_APP_LCD_URL;

const BASE_URL = Object.freeze({
  BANK: `${CHAIN_API_URL}/v1/bank`,
  COSWMWASM: `${CHAIN_LCD_URL}/cosmwasm/wasm/v1`,
  CW20: `${CHAIN_API_URL}/v1/cw20`,
  CW858: `${CHAIN_API_URL}/v1/cw858`,
  USERS: `${CHAIN_API_URL}/v1/users`,
});

const PARAM = Object.freeze({
  LIMIT: 30,
  MAX_LIMIT: 100,
  TYPE: {
    CW20: 'cw20',
    NATIVE: 'native',
  },
});

export default Object.freeze({
  BASE_URL,
  PARAM,
});

<template>
  <v-text-field
    dense
    outlined
    :disabled="disabled"
    :error-messages="errorMessages"
    v-model="model"
    @blur="v.$touch()"
    @input="v.$touch()"
  >
    <template #label>
      <span v-text="label" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'BaseTextField',
  emits: ['input'],
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    v: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    errorMessages() {
      const errors = [];
      if (!this.v || !this.v.$dirty) {
        return errors;
      }
      if ('required' in this.v && !this.v.required) {
        errors.push('This field is required');
        return errors;
      }
      if ('isAddressValid' in this.v && !this.v.isAddressValid) {
        errors.push('The address is not valid');
        return errors;
      }
      if ('isTRNCodeValid' in this.v && !this.v.isTRNCodeValid) {
        errors.push('The TRN code is not valid');
        return errors;
      }
      return errors;
    },
  },
};
</script>

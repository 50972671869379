const mockBalance = () => {
  const item = {
    amount: '1000000000',
    contract_address: 'did:com:1cjnpack2jqngdhj9cap23h4n3dmxcvqswgyrlc',
    decimals: 6,
    denom: 'uccc',
    name: 'Commercio Cash Coin',
  };
  return item;
};

const mockBalannces = (balances) => {
  const list = new Array(balances).fill(null).map(() => mockBalance());
  return list;
};

export { mockBalance, mockBalannces };

/**
 *
 * @param {String} value
 * @returns {Boolean}
 */
const isAddressValid = (value) => {
  const regEx = new RegExp('did:com:' + '[a-z0-9]{39}$', 'igm');
  return regEx.test(value.toLowerCase());
};

/**
 *
 * @param {String} value
 * @returns {Boolean}
 */
const isItalianFiscalCodeValid = (value) => {
  const regEx = new RegExp(
    /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i,
    'igm',
  );
  return regEx.test(value);
};

/**
 *
 * @param {String} value
 * @returns {Boolean}
 */
const isItalianVatValid = (value) => {
  const regEx = new RegExp(/^[0-9]{11}$/, 'igm');
  return regEx.test(value);
};

/**
 *
 * @param {Number, String} value
 * @returns {Boolean}
 */
const isPositiveAmount = (value) => {
  return parseFloat(value) > 0;
};

/**
 *
 * @param {String} value
 * @returns {Boolean}
 */
const isCROCodeValid = (value) => {
  const dividend = value.slice(0, 9);
  const check = value.slice(9, 11);
  const rest = parseInt(dividend) % 13;
  if (rest.toString().padStart(2, '0') !== check) {
    return false;
  }
  return true;
};

/**
 *
 * @param {String} value
 * @returns {Boolean}
 */
const isTRNCodeValid = (value) => {
  const regEx = new RegExp(
    /^[a-zA-Z0-9]{5}[0-9]{11}[a-zA-Z0-9]{12}[a-zA-Z]{2}$/,
    'igm',
  );
  if (!regEx.test(value)) {
    return false;
  }
  const CRO = value.slice(5, 16);
  return isCROCodeValid(CRO);
};

const validatorsUtility = {
  isAddressValid,
  isCROCodeValid,
  isItalianFiscalCodeValid,
  isItalianVatValid,
  isPositiveAmount,
  isTRNCodeValid,
};

export default validatorsUtility;

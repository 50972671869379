import { cw858Api } from '@/apis';

export default {
  async initMyAssetsDetail({ commit, dispatch }, contractAddress) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchDetail', contractAddress)];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchDetail({ commit, rootGetters }, contractAddress) {
    const token = rootGetters['token'];
    try {
      const response = await cw858Api.requestDetail(token, contractAddress);
      commit('setDetail', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};

const BACKEND = process.env.VUE_APP_BACKEND;
const HOST = process.env.VUE_APP_HOST;

const KEYCLOAK = Object.freeze({
  CLIENT_ID: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
  REALM: process.env.VUE_APP_KEYCLOAK_REALM,
  URL: process.env.VUE_APP_KEYCLOAK_URL,
  ON_LOAD: {
    CHECK_SSO: 'check-sso',
    LOGIN: 'login-required',
  },
  REFRESH_TOKEN_TIME: 60,
  USER_ATTRIBUTE: {
    NAME: process.env.VUE_APP_KEYCLOAK_USER_ATTRIBUTE_NAME,
    VALUE: process.env.VUE_APP_KEYCLOAK_USER_ATTRIBUTE_VALUE,
  },
});

export default Object.freeze({
  COPYRIGHT: 'Copyright',
  HOST,
  KEYCLOAK,
  SWAGGER: `${BACKEND}/swagger/index.html`,
});

import { CONFIG } from '@/constants';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  profile: (state) => state.profile,
  token: (state) => state.token,
  isAuthenticated: (state) => state.token !== '',
  isManager: (state) => {
    if (
      !state.profile ||
      !state.profile.attributes ||
      !state.profile.attributes[CONFIG.KEYCLOAK.USER_ATTRIBUTE.NAME] ||
      !state.profile.attributes[CONFIG.KEYCLOAK.USER_ATTRIBUTE.NAME].length
    ) {
      return false;
    }
    return (
      state.profile.attributes[CONFIG.KEYCLOAK.USER_ATTRIBUTE.NAME][0] ===
      CONFIG.KEYCLOAK.USER_ATTRIBUTE.VALUE
    );
  },
};

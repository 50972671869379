import { fakerIT as faker } from '@faker-js/faker';
import { LISTINGS } from '@/constants';

const colors = Object.values(LISTINGS.COLOR);

const mockMyAssetsSummary = () => {
  const item = {
    apr: faker.number.int({ max: 9, min: 1 }),
    txs: faker.number.int({ max: 19, min: 10 }),
    value: faker.number.int({ max: 499, min: 100 }),
  };
  return item;
};

const mockMyAssetsDetail = () => {
  const item = {
    balance: faker.string.numeric(5),
    marketing_info: {
      description: faker.lorem.sentence(),
      logo: {
        embedded: {
          png: 'iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB',
          svg: 'PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmc+',
        },
        url: 'https://example.com/logo.png',
      },
      marketing: 'did:com:18h03de6awcjk4u9gaz8s5l0xxl8ulxjctzsytd',
      project: 'https://example.com',
    },
    minter: {
      cap: faker.number.int({ max: 499, min: 100 }),
      minter: 'did:com:18h03de6awcjk4u9gaz8s5l0xxl8ulxjctzsytd',
    },
    token_info: {
      decimals: faker.number.int({ max: 9, min: 1 }),
      granularity: '1',
      name: 'cw858',
      symbol: 'CWT',
      total_supply: faker.number.int({ max: 499, min: 100 }),
    },
  };
  return item;
};

const mockMyAssetsItem = () => {
  const item = {
    balance: faker.string.numeric(5),
    contract_address:
      'did:com:1f590h62mz9lxj7r2w8uypyz0t6dr6uhehskla82k3tym8m8kr3ys54fdku',
    logo: {
      embedded: {
        png: 'iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB',
        svg: 'PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmc+',
      },
      url: 'https://example.com/logo.png',
    },
    name: 'cw858',
    symbol: 'CWT',
  };
  return item;
};

const mockMyAssetsItems = (items = 3) => {
  return faker.helpers.multiple(mockMyAssetsItem, {
    count: items,
  });
};

const mockMyAssetsManageSummary = () => {
  const item = {
    apr: faker.number.int({ max: 9, min: 1 }),
    price: faker.number.int({ max: 9, min: 1 }),
    titles: faker.number.int({ max: 999, min: 100 }),
    value: faker.number.int({ max: 999, min: 100 }),
  };
  return item;
};

const mockMyAssetsManageItem = () => {
  const color = faker.helpers.arrayElement(colors);
  const item = {
    color,
    created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
    id: faker.string.uuid(),
    total: faker.number.int({ max: 999, min: 10 }),
    tx_hash: faker.string.alphanumeric(64),
    tx_type: 'Acquisto',
    value: faker.number.int({ max: 99, min: 1 }),
  };
  return item;
};
const mockMyAssetsManageItems = (items = 3) => {
  return faker.helpers.multiple(mockMyAssetsManageItem, {
    count: items,
  });
};

export {
  mockMyAssetsDetail,
  mockMyAssetsItem,
  mockMyAssetsItems,
  mockMyAssetsManageItem,
  mockMyAssetsManageItems,
  mockMyAssetsManageSummary,
  mockMyAssetsSummary,
};

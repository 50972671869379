import axios from 'axios';
import { API } from '@/constants';
import { paramsHandler } from '@/utils';

const bankApi = {
  /**
   *
   * @param {string} token
   * @param {object} params
   * @returns {AxiosPromise}
   */
  requestBalance(token, params) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/balance',
      method: 'get',
      baseURL: API.BASE_URL.BANK,
      headers,
      params: paramsHandler.bindValuedParams(params),
    });
  },
  /**
   *
   * @param {string} token
   * @param {object} params
   * @returns {AxiosPromise}
   */
  requestReceived(token, params) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/received',
      method: 'get',
      baseURL: API.BASE_URL.BANK,
      headers,
      params: paramsHandler.bindValuedParams(params),
    });
  },
  /**
   *
   * @param {string} token
   * @param {object} data
   * @returns {AxiosPromise}
   */
  requestSend(token, data) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/send',
      method: 'post',
      baseURL: API.BASE_URL.BANK,
      headers,
      data,
    });
  },
  /**
   *
   * @param {string} token
   * @param {object} params
   * @returns {AxiosPromise}
   */
  requestSent(token, params) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/sent',
      method: 'get',
      baseURL: API.BASE_URL.BANK,
      headers,
      params: paramsHandler.bindValuedParams(params),
    });
  },
};

export default bankApi;

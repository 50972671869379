import { ROUTE } from '@/constants';
import store from '@/store';
import { loadView } from '../index';

const EregAuthoritiesView = loadView('ereg/EregAuthoritiesView');
const EregConstraintRegistryView = loadView('ereg/EregConstraintRegistryView');
const EregTransactionRegistryView = loadView(
  'ereg/EregTransactionRegistryView',
);
const EregUserRegistryView = loadView('ereg/EregUserRegistryView');

const eregRoute = {
  path: ROUTE.PATH.EREG,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  beforeEnter: (_to, _from, next) => {
    const isManager = store.getters['isManager'];
    if (!isManager) {
      next({ name: ROUTE.NAME.HOME });
    } else {
      next();
    }
  },
  meta: {
    auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
  },
  children: [
    {
      path: '',
      name: ROUTE.NAME.EREG,
      component: EregUserRegistryView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
      },
    },
    {
      path: ROUTE.PATH.EREG_AUTHORITIES,
      name: ROUTE.NAME.EREG_AUTHORITIES,
      component: EregAuthoritiesView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
      },
    },
    {
      path: ROUTE.PATH.EREG_CONSTRAINT_REGISTRY,
      name: ROUTE.NAME.EREG_CONSTRAINT_REGISTRY,
      component: EregConstraintRegistryView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
      },
    },
    {
      path: ROUTE.PATH.EREG_TRANSACTION_REGISTRY,
      name: ROUTE.NAME.EREG_TRANSACTION_REGISTRY,
      component: EregTransactionRegistryView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
      },
    },
    {
      path: ROUTE.PATH.EREG_USER_REGISTRY,
      name: ROUTE.NAME.EREG_USER_REGISTRY,
      component: EregUserRegistryView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
      },
    },
  ],
};

export default eregRoute;

import { ROUTE } from '@/constants';
import { loadView } from '../index';

const NotFoundView = loadView('NotFoundView');

const notFoundRoute = {
  path: ROUTE.PATH.NOT_FOUND,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
  },
  children: [
    {
      path: '',
      name: ROUTE.NAME.NOT_FOUND,
      component: NotFoundView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
      },
    },
    {
      path: '*',
      redirect: ROUTE.PATH.NOT_FOUND,
    },
  ],
};

export default notFoundRoute;

import Vue from 'vue';
import VueRouter from 'vue-router';

import { ROUTE } from '@/constants';
import navigationGuard from './navigation-guard';
import bankRoute from './routes/bank-route';
import cw20Route from './routes/cw20-route';
import eregRoute from './routes/ereg-route';
import homeRoute from './routes/home-route';
import loginRoute from './routes/login-route';
import myAssetsRoute from './routes/my-assets-route';
import notFoundRoute from './routes/not-found-route';
import toolRegistryRoute from './routes/tool-registry-route';
import usersRoute from './routes/users-route';

Vue.use(VueRouter);

const routes = [
  {
    path: ROUTE.PATH.ROOT,
    redirect: () => ROUTE.PATH.HOME,
    meta: {
      auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
    },
  },
];

routes.push(
  bankRoute,
  cw20Route,
  eregRoute,
  homeRoute,
  loginRoute,
  myAssetsRoute,
  toolRegistryRoute,
  usersRoute,
);
routes.push(notFoundRoute);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, _from, next) => {
  if (to.meta.auth === ROUTE.AUTH.USER_ROLE.ANY) {
    next();
    return;
  }

  const userRole = await navigationGuard.setupAuthorization();
  if (userRole === ROUTE.AUTH.USER_ROLE.NOT_AUTHENTICATED) {
    next(ROUTE.PATH.LOGIN);
    return;
  } else {
    next();
    return;
  }
});

export default router;

export function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}

import axios from 'axios';
import { API } from '@/constants';

const cw858Api = {
  /**
   *
   * @param {string} token
   * @param {object} data
   * @returns {AxiosPromise}
   */
  requestSend(token, data) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/send',
      method: 'post',
      baseURL: API.BASE_URL.CW858,
      headers,
      data,
    });
  },
  /**
   *
   * @param {string} token
   * @param {string} contractAddress
   * @returns {AxiosPromise}
   */
  requestDetail(token, contractAddress) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/tokens/${contractAddress}`,
      method: 'get',
      baseURL: API.BASE_URL.CW858,
      headers,
    });
  },
};

export default cw858Api;

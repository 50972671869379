import { bankApi, myAssetsApi } from '@/apis';

export default {
  async initMyAssetsDashboard({ commit, dispatch }, tokenType) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchList', { token_type: tokenType }),
      dispatch('fetchSummary'),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchList({ commit, rootGetters }, params) {
    const token = rootGetters['token'];
    try {
      const response = await bankApi.requestBalance(token, params);
      commit('addAssets', response.data.balance);
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetchSummary({ commit }) {
    try {
      const response = await myAssetsApi.requestSummary();
      commit('setSummary', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};

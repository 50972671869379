import axios from 'axios';
import { API } from '@/constants';

const usersApi = {
  /**
   *
   * @param {string} token
   * @returns {AxiosPromise}
   */
  requestDetail(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      method: 'get',
      baseURL: API.BASE_URL.USERS,
      headers,
    });
  },
  /**
   *
   * @param {string} token
   * @returns {AxiosPromise}
   */
  requestCreate(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      method: 'post',
      baseURL: API.BASE_URL.USERS,
      headers,
    });
  },
};

export default usersApi;

import { ROUTE } from '@/constants';
import { loadView } from '../index';

const MyAssetsDashboardView = loadView('my-assets/MyAssetsDashboardView');
const MyAssetsDetailView = loadView('my-assets/MyAssetsDetailView');
const MyAssetsManageAuthView = loadView(
  'my-assets/manage/MyAssetsManageAuthView',
);
const MyAssetsManageConfirmView = loadView(
  'my-assets/manage/MyAssetsManageConfirmView',
);
const MyAssetsManageDashboardView = loadView(
  'my-assets/manage/MyAssetsManageDashboardView',
);
const MyAssetsManageRouter = loadView('my-assets/MyAssetsManageRouter');
const MyAssetsManageSellView = loadView(
  'my-assets/manage/MyAssetsManageSellView',
);
const MyAssetsManageSendView = loadView(
  'my-assets/manage/MyAssetsManageSendView',
);

const myAssetsRoute = {
  path: ROUTE.PATH.MY_ASSETS,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
  },
  children: [
    {
      path: '',
      name: ROUTE.NAME.MY_ASSETS,
      component: MyAssetsDashboardView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
      },
    },
    {
      path: ROUTE.PATH.MY_ASSETS_DASHBOARD,
      name: ROUTE.NAME.MY_ASSETS_DASHBOARD,
      component: MyAssetsDashboardView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
      },
    },
    {
      path: `${ROUTE.PATH.MY_ASSETS_DETAIL}/:id`,
      name: ROUTE.NAME.MY_ASSETS_DETAIL,
      component: MyAssetsDetailView,
      props: (route) => ({ ...route.params }),
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
      },
    },
    {
      path: `${ROUTE.PATH.MY_ASSETS_MANAGE}/:id`,
      component: MyAssetsManageRouter,
      props: (route) => ({ ...route.params }),
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
      },
      children: [
        {
          path: '',
          name: ROUTE.NAME.MY_ASSETS_MANAGE,
          component: MyAssetsManageDashboardView,
          props: (route) => ({ ...route.params }),
          meta: {
            auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
          },
        },
        {
          path: ROUTE.PATH.MY_ASSETS_MANAGE_AUTH,
          name: ROUTE.NAME.MY_ASSETS_MANAGE_AUTH,
          component: MyAssetsManageAuthView,
          props: (route) => ({ ...route.params }),
          meta: {
            auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
          },
        },
        {
          path: ROUTE.PATH.MY_ASSETS_MANAGE_CONFIRM,
          name: ROUTE.NAME.MY_ASSETS_MANAGE_CONFIRM,
          component: MyAssetsManageConfirmView,
          props: (route) => ({ ...route.params }),
          meta: {
            auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
          },
        },
        {
          path: ROUTE.PATH.MY_ASSETS_MANAGE_DASHBOARD,
          name: ROUTE.NAME.MY_ASSETS_MANAGE_DASHBOARD,
          component: MyAssetsManageDashboardView,
          props: (route) => ({ ...route.params }),
          meta: {
            auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
          },
        },
        {
          path: ROUTE.PATH.MY_ASSETS_MANAGE_SELL,
          name: ROUTE.NAME.MY_ASSETS_MANAGE_SELL,
          component: MyAssetsManageSellView,
          props: (route) => ({ ...route.params }),
          meta: {
            auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
          },
        },
        {
          path: ROUTE.PATH.MY_ASSETS_MANAGE_SEND,
          name: ROUTE.NAME.MY_ASSETS_MANAGE_SEND,
          component: MyAssetsManageSendView,
          props: (route) => ({ ...route.params }),
          meta: {
            auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
          },
        },
      ],
    },
  ],
};

export default myAssetsRoute;

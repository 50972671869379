import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';
import bank from './bank';
import cw20 from './cw20';
import eregAuthorities from './ereg/authorities';
import eregConstraintRegistry from './ereg/constraint-registry';
import eregTxRegistry from './ereg/tx-registry';
import eregUserRegistry from './ereg/user-registry';
import myAssetsDashboard from './my-assets/dashboard';
import myAssetsDetail from './my-assets/detail';
import myAssetsManage from './my-assets/manage';
import toolRegistryCreate from './tool-registry/create';
import toolRegistryDashboard from './tool-registry/dashboard';
import toolRegistryEdit from './tool-registry/edit';

import users from './users';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    bank,
    cw20,
    eregAuthorities,
    eregConstraintRegistry,
    eregTxRegistry,
    eregUserRegistry,
    myAssetsDashboard,
    myAssetsDetail,
    myAssetsManage,
    toolRegistryCreate,
    toolRegistryDashboard,
    toolRegistryEdit,
    users,
  },
});

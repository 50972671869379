import { ROUTE } from '@/constants';
import store from '@/store';

const navigationGuard = {
  async setupAuthorization() {
    if (!store.getters['isAuthenticated']) {
      await store.dispatch('initAuth');
    }
    return store.getters['isAuthenticated']
      ? ROUTE.AUTH.USER_ROLE.AUTHENTICATED
      : ROUTE.AUTH.USER_ROLE.NOT_AUTHENTICATED;
  },
};

export default navigationGuard;

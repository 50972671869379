const stringUtility = {
  decodeFromBase64(data) {
    return atob(data);
  },
  decodeFromHex(data) {
    return data
      .match(/.{2}/g)
      .map((byte) => String.fromCharCode(parseInt(byte, 16)))
      .join('');
  },
  encodeToBase64(data) {
    return btoa(data);
  },
  encodeToHex(data) {
    let hex = '';
    for (let i = 0; i < data.length; i++) {
      const charCode = data.charCodeAt(i).toString(16);
      hex += charCode.padStart(2, '0');
    }
    return hex;
  },
  formatChainAddress({ address, start = 14, end = 5 } = {}) {
    const chars = address.length;
    return `${address.slice(0, start)}...${address.slice(chars - end, chars)}`;
  },
  removeDoubleQuotes(data) {
    return data.replace(/["]+/g, '');
  },
};

export default stringUtility;

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  isSending: (state) => state.isSending,
  isAdding: (state) => state.isAdding,
  list: (state) => state.list,
  paging: (state) => state.paging,
  summary: (state) => state.summary,
  cursor: (state) =>
    state.paging && state.paging.next_cursor ? state.paging.next_cursor : null,
};

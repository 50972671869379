import { cw858Api, myAssetsApi } from '@/apis';

export default {
  async initMyAssetsSend({ commit, dispatch }, address) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('myAssetsDetail/initMyAssetsDetail', address, { root: true }),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async sendMyAssets({ commit, rootGetters }, data) {
    const token = rootGetters['token'];
    commit('setSending', true);
    try {
      const response = await cw858Api.requestSend(token, data);
      return response.data.tx_hash;
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setSending', false);
    }
  },

  async initMyAssetsManageDashboard({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchList'), dispatch('fetchSummary')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async incrementTxs({ commit, dispatch }) {
    commit('setAdding', true);
    await dispatch('fetchList');
    commit('setAdding', false);
  },
  async fetchList({ commit }) {
    try {
      const response = await myAssetsApi.requestManageList();
      commit('addTxs', response.data.txs);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetchSummary({ commit }) {
    try {
      const response = await myAssetsApi.requestManageSummary();
      commit('setSummary', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};

import { ROUTE } from '@/constants';
import { loadView } from '../index';

const CW20ReceivedView = loadView('cw20/CW20ReceivedView');
const CW20SendView = loadView('cw20/CW20SendView');
const CW20SentView = loadView('cw20/CW20SentView');

const cw20Route = {
  path: ROUTE.PATH.CW20,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
  },
  children: [
    {
      path: '',
      name: ROUTE.NAME.CW20,
      component: CW20ReceivedView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
      },
    },
    {
      path: ROUTE.PATH.CW20_RECEIVED,
      name: ROUTE.NAME.CW20_RECEIVED,
      component: CW20ReceivedView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
      },
    },
    {
      path: ROUTE.PATH.CW20_SEND,
      name: ROUTE.NAME.CW20_SEND,
      component: CW20SendView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
      },
    },
    {
      path: ROUTE.PATH.CW20_SENT,
      name: ROUTE.NAME.CW20_SENT,
      component: CW20SentView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHENTICATED,
      },
    },
  ],
};

export default cw20Route;
